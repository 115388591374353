<template>
  <div>
    <customer-list v-if="userData1.role === 'PLSYR' && $vuetify.breakpoint.smAndDown" />

    <v-alert v-if="userData1.role === 'PLSYR' && !$store.state.plasiyerCustomer" tile type="warning">
      Lütfen yukarıdan bir müşteri seçin
    </v-alert>

    <v-container v-else>
      <v-row>
        <v-col class="d-block d-md-flex justify-center pt-2 pb-6 py-md-16">
          <v-card
            elevation="16"
            :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : 'lg'"
            :width="$vuetify.breakpoint.mdAndUp ? 750 : '100%'"
          >
            <v-card-title class="grey">
              <v-icon :size="$vuetify.breakpoint.mdAndUp ? '60' : '30'" left>
                {{ icons.mdiMapMarker }}
              </v-icon>
              <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-body-1'">Adreslerim</span>
              <v-spacer />

              <v-chip link outlined label small> {{ userData.length }} Adet </v-chip>
            </v-card-title>

            <v-divider />
            <div v-for="(item, i) in userData" :key="`${i}-adres`">
              <v-card-text :class="i % 2 ? 'nightDarkTon' : null">
                <v-list three-line color="transparent">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-n3">
                        <v-chip small label>
                          {{ item.address_title }}
                        </v-chip>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="py-2">
                    <v-list-item-avatar>
                      <v-avatar color="grey">
                        <v-icon size="24">
                          {{ icons.mdiMap }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle> Adres </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.address }}

                        {{ item.state }}

                        {{ item.city_txt }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="py-2">
                    <v-list-item-avatar>
                      <v-avatar color="grey">
                        <v-icon size="24">
                          {{ icons.mdiPhone }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle class="mb-1"> Telefon </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.mobile }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="py-2">
                    <v-list-item-avatar>
                      <v-avatar color="grey">
                        <v-icon size="24">
                          {{ icons.mdiCalendar }}
                        </v-icon>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-subtitle class="mb-1"> Kayıt Tarihi </v-list-item-subtitle>
                      <v-list-item-title>
                        {{ item.create_date }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-divider v-if="i < userData.length - 1" />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import store from '@/store'
import { mdiAccountCircle, mdiAt, mdiCalendar, mdiMap, mdiMapMarker, mdiPhone, mdiUpdate } from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const { uid } = JSON.parse(localStorage.getItem('userData'))
    const userData1 = JSON.parse(localStorage.getItem('userData'))

    //const { router } = useRouter()
    const userData = ref([])
    const loading = ref(true)
    const pageLoading = ref(true)
    onMounted(() => {
      console.log(store.state.secondState)
      // if (userData.role == 'PLSYR' && store.state.secondState === true) {
      //   router.push({ name: 'dashboard' })
      // }
      let id
      // if (router.currentRoute.params.id) {
      //   id = router.currentRoute.params.id
      // } else {
      //   id = uid
      // }
      getDatas(id)
    })

    const getDatas = id => {
      const method = 'getAddressListByUid'
      store
        .dispatch('postMethod', { method, id })
        .then(response => {
          if (response.error === 0) {
            userData.value = response.response
            pageLoading.value = false
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    }

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        //console.log(store.state.plasiyerCustomer)
        getDatas()
        // if (userData.role == 'PLSYR' && store.state.secondState === true) {
        //   router.push({ name: 'dashboard' })
        // }
      },
    )
    // ui

    return {
      userData,
      loading,
      getDatas,
      pageLoading,
      userData1,
      icons: {
        mdiMapMarker,
        mdiAccountCircle,
        mdiMap,
        mdiPhone,
        mdiAt,
        mdiCalendar,
        mdiUpdate,
      },
    }
  },
}
</script>
